import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Hr from '@components/Hr'

const Hero = styled(({ data: { title, ptext }, className }) => (
  <div
    className={`${className} d-flex align-items-center position-relative bg-alabaster`}
  >
    <Container>
      <Row>
        <Col xs={12} lg={6}>
          <h1 className="text-black mb-3">{title}</h1>
          <Hr className="mb-3" />
          {ptext.map((item, index)=>(
            <p className="text-gray mb-0" key={index}>{item.paragraph}</p>
          ))}
        </Col>
      </Row>
    </Container>
  </div>
))`
  height: auto;
  padding: 50px 0 170px;

  @media (min-width: 768px) {
    padding: 50px 0 250px;
  }

  @media (min-width: 992px) {
    height: 480px;
    padding: inherit;
  }

  h1 {
    font-size: 28px;
    line-height: 38px;

    @media (min-width: 992px){
      font-size: 48px;
      line-height: 58px;
    }
  }
`

Hero.propTypes = {
  data: object.isRequired
}

Hero.defaultProps = {
  data: {}
}

export default Hero
