import React from 'react'
import { object } from 'prop-types'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

// components
import DivImageBackground from '@components/DivImageBackground'

// utils
import colors from '@styles/js/colors'

const City = styled(
  ({ data: { name, phone, email, color, cimage }, fluid, className }) => (
    <div className={`${className} mb-5 position-relative`}>
      <div className="img-wrapper">
        <div
          className="decoration"
          css={css`
            background: linear-gradient(
              to bottom left,
              transparent 0%,
              transparent 50%,
              ${color
                ? `${color} 50%, ${color} 90%`
                : `#c06dbb 50%,
            #c06dbb 99%`}
            );
          `}
        />
        <DivImageBackground
          alt={cimage.altText ? cimage.altText : name}
          fluid={fluid}
          className="custom"
          xsh={230}
          lgh={600}
        />
      </div>
      <div className="text-wrapper w-100 pt-4">
        <h3 className="text-white font-20 font-weight-bold">{name}</h3>
        {phone && <p className="text-white text">{phone}</p>}
        {email && (
          <p className="email position-relative text-white mb-0">{email}</p>
        )}
      </div>
    </div>
  )
)`
  z-index: 100;

  .img-wrapper {
    position: relative;
    z-index: -1;

    .decoration {
      bottom: 0;
      height: 115px;
      height: 115px;
      left: 0;
      position: absolute;
      transition: all 100ms linear;
      width: 115px;
    }

    .custom {
      bottom: 10px;
    }
  }

  .text-wrapper {
    margin-top: -10px;

    .text {
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }

    .email {
      font-size: 0.875rem;
      position: relative;
      width: 144px;

      &:after {
        background-color: ${colors.saffron};
        content: '';
        display: block;
        height: 1px;
        left: 154px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
      }
    }
  }
`

City.propTypes = {
  data: object.isRequired,
  fluid: object.isRequired
}

City.defaultProps = {
  data: {},
  fluid: {}
}

export default City
