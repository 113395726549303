import { useStaticQuery, graphql } from "gatsby"

export const contactContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "220", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Herodata {
                title
                ptext {
                  paragraph
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Contactsdata {
                bgimage {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile{
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                contact {
                  title
                  phone
                  fax
                  email
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Citiesdata {
                city {
                  cimage {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile{
                      childImageSharp {
                        fluid(maxWidth: 740, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  name
                  phone
                  email
                  color
                }
              }
            }
          }
        }
      }
    }
  `)
  return [
    data.wpgraphql.page.AllPageElements.pageelements[0],
    data.wpgraphql.page.AllPageElements.pageelements[1],
    data.wpgraphql.page.AllPageElements.pageelements[2],
    data.wpgraphql.page.AllPageElements.pageelements[3]
  ]
}
