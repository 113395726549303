import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Contact from '@compositions/Contact'

const BlogPage = () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  )
}

export default BlogPage
