import React from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'

import colors from '@styles/js/colors'

const ContactInfo = styled(({ title, phone, fax, email, className }) => (
  <div className={`${className} mb-3 mb-md-5`}>
    <h2 className="text-white font-20">{title}</h2>
    <p>
      <span className="text-white text pr-3">{phone}</span>
      {fax && <span className="text-white text">Fax: {fax}</span>}
    </p>
    <p className="text-white position-relative mb-0 email">{email}</p>
  </div>
))`
  float: left;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  .text {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .email {
    font-size: 0.875rem;
    width: 144px;

    &:after {
      background-color: ${colors.saffron};
      content: '';
      display: block;
      height: 1px;
      left: 154px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
    }
  }
`

ContactInfo.propTypes = {
  title: string.isRequired,
  phone: string.isRequired,
  email: string.isRequired
}

ContactInfo.defaultProps = {
  title: '',
  phone: '',
  email: ''
}

export default ContactInfo
