import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BackgroundImage from 'gatsby-background-image'

// components
import SEO from '@components/SEO'
import DescribeHome from '@components/DescribeHome'
import Hero from './components/Hero'
import City from './components/City'
import ContactInfo from './components/ContactInfo'

import colors from '@styles/js/colors'

// graphql
import { contactContent } from './modules/graphql'

const ContactPage = styled(({ className }) => {
  const seoData = contactContent()[0]
  const heroData = contactContent()[1]
  const contactsData = contactContent()[2]
  const citiesData = contactContent()[3]

  return (
    <>
      <SEO title={seoData.title} description={seoData.description} />
      <Hero data={heroData} />
      <div className={`${className} mb-3 d-flex w-100`}>
        <div className="mb-5 w-100">
          <div className="bg-cerulean-blue pb-3 pb-md-5">
            <Container>
              <BackgroundImage
                Tag="section"
                className="row align-items-center background-img"
                fluid={contactsData.bgimage.imageFile.childImageSharp.fluid}
                backgroundColor={`#2b58ca`}
                alt={
                  contactsData.bgimage.altText
                    ? contactsData.bgimage.altText
                    : 'Contact Background'
                }
              >
                <Col lg={6} xl={6} className="d-none d-lg-block"></Col>
                <Col lg={6} xl={6}>
                  <div className="contact-form bg-white px-3 px-sm-5 py-5">
                    <DescribeHome />
                  </div>
                  <section className="mt-5 d-inline-block w-100 mb-3 mb-md-5">
                    {contactsData.contact.map((item, index) => (
                      <ContactInfo
                        title={item.title}
                        phone={item.phone}
                        fax={item.fax}
                        email={item.email}
                        className="mb-5"
                        key={index}
                      />
                    ))}
                  </section>
                </Col>
              </BackgroundImage>
              <Row>
                {citiesData.city.map((item, cityIndex) => {
                  return (
                    <Col md={4} key={cityIndex}>
                      <City
                        data={item}
                        fluid={item.cimage.imageFile.childImageSharp.fluid}
                        key={cityIndex}
                      />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  )
})`
  .ReCAPTCHA{
    margin-bottom: 1.5rem;
  }

  .background-img {
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    overflow: visible !important;
    width: 100%;

    &:before {
      background-size: contain !important;
      background-position: center 30% !important;
    }
  }

  .contact-form {
    margin-top: -130px;

    @media (min-width: 768px) {
      margin-top: -200px;
    }

    @media (min-width: 992px) {
      margin-top: -375px;
    }

    h3 {
      color: ${colors.black} !important;
    }

    p {
      color: ${colors.gray};
    }

    .form-label {
      color: ${colors.washedBlack} !important;
    }

    .form-control {
      border: 1px solid ${colors.satinWhite} !important;
    }

    .form-select {
      position: relative;

      .dropdown-icon {
        background-size: cover;
        display: block;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 18px;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

        &::-ms-expand {
          display: none;
        }
      }
    }
  }
`
export default ContactPage
