import React from 'react'
import { object, number, string } from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-background-image'

import colors from '@styles/js/colors'

const DivImageBackground = styled(({ fluid, alt, className }) => (
  <Img
    className={`${className}`}
    Tag="section"
    fluid={fluid}
    alt={alt}
    backgroundColor={colors.fuchsiaPink}
  />
))`
  background-position: center;
  background-size: cover;
  left: 10px;
  height: ${({ xsh }) => (xsh ? `${xsh}px` : ``)};
  position: absolute;
  width: calc(100% - 10px);

  @media (min-width: 768px) {
    width: calc(100% - 20px);
  }

  ${({ smh }) =>
    smh &&
    `@media (min-width: 768px) {
    height: ${({ smh }) => (smh ? `${smh}px` : ``)};
  }`}

  ${({ mdh }) =>
    mdh &&
    `@media (min-width: 992px) {
    height: ${({ mdh }) => (mdh ? `${mdh}px` : ``)};
  }`}

  ${({ lgh }) =>
    lgh &&
    `@media (min-width: 1200px) {
    height: ${({ lgh }) => (lgh ? `${lgh}px` : ``)};
  }`}
`

DivImageBackground.propTypes = {
  fluid: object.isRequired,
  alt: string,
  xsh: number,
  smh: number,
  mdh: number,
  lgh: number
}

DivImageBackground.defaultProps = {
  fluid: {},
  alt: 'Background Image',
  xsh: 250
}

export default DivImageBackground
